// Customizable Area Start
import React from "react";
import { Grid, Button, Typography, Divider, InputBase, Box } from "@material-ui/core";
import { createTheme, ThemeProvider, Theme, withStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons"
import { Pagination } from '@material-ui/lab';



const theme = createTheme({
    palette: {
        primary: {
            main: "#FE7F2D",
            contrastText: "#fff"
        }
    },
    typography: {
        fontFamily: "Inter, sans-serif",
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightBold: 600,
        h6: {
            fontWeight: 500
        },
        subtitle1: {
            margin: "20px 0px",
            fontSize: 12, // Custom font size for subtitle1
            fontWeight: 400 // Custom font weight for subtitle1
        }
        // Add more custom typography styles here as needed
    }
});

import AdvancedSearchController, { Props } from "./AdvancedSearchController";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import ServiceRatingFilter from "../../../components/src/ServiceRatingFilter";
import LazyImage from "../../../components/src/LazyImage";
import FilterSelect from "../../../components/src/FilterSelect";
import { primaryColor } from "../../../components/src/theme";

const sortByFilter = [
    { value: 'AtoZ', label: 'Alphabet (A to Z)' },
    { value: 'ZtoA', label: 'Alphabet (Z to A)' },
    { value: 'highToLow', label: 'Rating (high to low)' },
    { value: 'lowToHigh', label: 'Rating (low to high)' },]

export default class ServiceProviderList extends AdvancedSearchController {
    constructor(props: Props) {
        super(props);
    }

    render() {

        const { classes } = this.props;

        const {
            providerType,
            serviceProviderList,
            serviceRating,
            countByRatingOption
        } = this.state;

        const page_options = {
            total_pages: 2,
            per_page: 12,
            current_page: 1,
            total_items: 20
        };


        const searchBlock = () => {
            return <div className={`${classes.search}`}>
                <InputBase
                    data-test-id="searchId"
                    placeholder="Where to?"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ className: classes.searchBar }}
                    onChange={(event) => {
                        this.handleSearchValue(event.target.value)
                    }}
                />
                <Button className={classes.searchIcon}>
                    <Search /> Search
                </Button>
            </div>
        }
        return (
            <ThemeProvider theme={theme}>
                <div
                    className={classes.container}
                >
                    <div className={classes.container}>
                        <div className={classes.alignItemsStyle}>
                            <Breadcrumbs list={[
                                {
                                    title: 'Home',
                                    link: '/LandingPage'
                                },
                                {
                                    title: `Travel ${providerType} listing`,
                                },
                            ]} />
                            {searchBlock()}
                        </div>
                        <Divider style={{ margin: '32px 0' }} />
                        <div>
                            <Typography className={classes.mainTitle}>List of Travel {providerType}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '32px 0', width: '100%' }}>
                                <div style={{ marginLeft: 'auto' }}>
                                    <FilterSelect
                                        field="Sort by"
                                        options={sortByFilter}
                                        filterName="sortBy"
                                        handleFilterChange={this.handleSorting}
                                    />
                                </div>
                            </div>
                        </div>

                        <Grid container spacing={5}>
                            <Grid item md={3}>
                                <div style={{ border: '1px solid #C3CAD9', borderRadius: '8px', padding: '12px' }}>
                                    <Typography>Filter</Typography>
                                    <Divider style={{ marginTop: '8px' }} />
                                    <ServiceRatingFilter 
                                        selectedValue={serviceRating}
                                        serviceRatingChange={this.handleServiceRatingChange} 
                                        countByRatingOption={countByRatingOption}  
                                    />
                                </div>

                            </Grid>
                            <Grid item md={9}>
                                <Grid container spacing={3}>
                                    {serviceProviderList.map((user, index) => {
                                        const { name, image } = user.attributes;
                                        const {id, type} = user;
                                        return (
                                            <Grid item md={3} sm={4} key={index}>
                                                <div data-test-id="checkid" className={classes.innerSlideBlock}>
                                                    <LazyImage src={image} alt="Image" className={`${classes.image} ${classes.profileImg}`}
                                                    data-test-id="navigateToSPDPage1"
                                                    onClick={() => this.navigateToProviderDetails(type, id)}
                                                    />
                                                    <Typography className={`${classes.sliderText}`}
                                                    data-test-id="navigateToSPDPage2"
                                                    onClick={() => this.navigateToProviderDetails(type, id)}
                                                    >
                                                        {name}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                                    <Grid item md={3}>
                                        {""}
                                    </Grid>
                                    <Grid item md={9}>
                                    {page_options.total_items > 12 &&
                                        <Box position={"relative"}>
                                            <Box marginY={5}>
                                                <Pagination
                                                    count={page_options.total_items}
                                                    page={Number(page_options.current_page)}
                                                    onChange={() => { }}
                                                    // defaultPage={1}
                                                    classes={{
                                                        root: classes.paginationRoot,
                                                        ul: classes.paginationItem
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    }
                                    </Grid>
                        </Grid>
                    </div>




                </div>
            </ThemeProvider>
        );
    }
}
export const providerListStyles = (theme: Theme) => ({
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '32px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 600,
        color: '#212426',
        // margin: "22px auto 32px", // Center the div horizontally
    },
    breadCrumbStyle: {
        display: 'inline'
    },
    container: {
        margin: '49px auto 120px',
        padding: '0 26px 0 38px',
        width: '100%'
    },
    alignItemsStyle: {
        display: 'flex',
        alignItems: 'center'
    },
    txtBtn: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        marginBottom: "24px"
    },
    btnBtn: {
        display: "flex",
        flexWrap: "wrap" as const,
        justifyContent: "flex-end",
        gap: "24px"
    },
    search: {
        borderRadius: '53px',
        border: '0.5px solid rgba(33, 36, 38, 0.50)',
        background: '#FFF',
        maxWidth: '700px',
        height: '64px',
        position: 'relative' as 'relative',
        backgroundColor: '#FFF',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
    },
    inputRoot: { paddingLeft: '32px' },
    searchBar: {
        color: 'rgba(33, 36, 38, 0.50)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    searchIcon: {
        right: '12px',
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute' as 'absolute',
        pointerEvents: 'none' as 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FE7F2D',
        textTransform: 'none' as 'none',
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        borderRadius: '25px',
        width: '113px',
        maxHeight: '40px',
    },
    innerSlideBlock: {
        maxHeight: '234px',
        maxWidth: '280px',
        position: 'relative' as const
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '16px',
        // objectFit: 'fill' as 'fill',
    },
    profileImg: {
        width: '160px !important',
        height: '160px !important',
        cursor: 'pointer'
    },
    sliderText: {
        marginTop: '16px',
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        cursor: 'pointer',
        '&.travelAgency': {
            textAlign: 'center'
        }
    },
    paginationRoot: {
        margin: 'auto',
        '& li:first-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
        '& li:last-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
        '& .MuiPaginationItem-root': {
            color: 'rgba(33, 36, 38, 0.50)',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.1px',
            display: 'flex',
            width: '60.206px',
            height: '32px',
            justifyContent: 'center',
            alignItems: 'center',
            '&.Mui-selected': {
                color: '#FE7F2D',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        }
    },
    paginationItem: {
        "& .Mui-selected, & .Mui-selected:hover": {
            backgroundColor: "transparent", // Change this to the desired background color
            color: primaryColor, // Change this to the desired text color
        }
    },
    filtersContainer: {
        width: "100%",
        maxWidth: "300px",
        border: "1px solid grey",
        borderRadius: "8px"
      },
    [theme.breakpoints.down(1280)]: {
        mainTitle: {
            fontFamily: 'Inter',
            fontSize: '28px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            // margin: "22px auto 32px", // Center the div horizontally
        },
    },
    [theme.breakpoints.down(768)]: {
        mainTitle: {
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            // margin: "22px auto 32px", // Center the div horizontally
        },
    },
    [theme.breakpoints.up(1280)]: {
        breadCrumbStyle: {
            display: 'none'
        }
    },
    [theme.breakpoints.down(360)]: {
        txtBtn: {
            display: "flex",
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: 'center',
            marginBottom: "24px",
            gap: '24px'
        },
        btnBtn: {
            display: "flex",
            flexDirection: 'row',
            // flexWrap: "wrap" as const,
            justifyContent: "space-between",
            // gap: "24px",
            width: '100%',
        },
        search: {
            height: '56px'
        },
        inputRoot: {
            padding: '18px 24px 19px'
        },
        searchBar: {
            fontSize: '16px',
            padding: 0
        },
        searchIcon: {
            padding: 0,
            fontSize: 0,
            width: '40px',
            height: '40px',
            minWidth: 'auto',
            '& svg': {
                width: '16px'
            }
        },
        innerSlideBlock: {
            maxWidth: '120px'
        },
        image: {
            borderRadius: '8px'
        },
    }
})
const ServiceProviderListWeb = withStyles(providerListStyles)(ServiceProviderList)
export { ServiceProviderListWeb }
// Customizable Area End
