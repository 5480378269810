import React from 'react';
import {
    Box,
    Modal,
    Typography,
    Card,
    TextField,
    Avatar
} from "@material-ui/core";
import { createTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";

import { Close } from "@material-ui/icons";
import { arrowRightCircle } from '../../blocks/ContentManagement/src/assets';
import { IChatListItem, IProcessedMessage } from '../../blocks/ContentManagement/src/ChatController';


const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

interface Props {
    chatBoxModal: boolean;
    allChatBoxMessages: IProcessedMessage;
    sendMessageData: string;
    receiverProfile: string | null ;
    receiverInfo: IChatListItem;
    handleSendMessage: (message: string) => void;
    sendChatMessageRequest: () => void;
    closeChatBoxModal: () => void;
}


const ChatBoxPopup: React.FC<Props> = ({
    chatBoxModal,
    allChatBoxMessages,
    sendMessageData,
    receiverInfo,
    receiverProfile,
    handleSendMessage,
    closeChatBoxModal,
    sendChatMessageRequest
}) => {

    const classes = stylesAddReview();

    
    return (
        <ThemeProvider theme={theme}>
            <Modal open={chatBoxModal}>
                <Box className={classes.paperMainWrappercum}>
                    <Box className={classes.modalEditContentcum}>
                        <Card style={{
                            borderRadius: '30px',
                            boxShadow: 'none',
                            background: '#FCFAF7',
                            // width: '766px',
                            height: '80vh',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '32px',
                            border: '1px solid rgba(33, 36, 38, 0.1)',
                            position: 'relative'
                        }} data-testid="chatList">
                            <Box style={{
                                position: 'absolute',
                                top: 20,
                                right: 15
                            }}>
                                <Box onClick={() => closeChatBoxModal()} className={classes.closeButtoncum}><Close style={{ fontSize: '20px' }} /></Box>
                            </Box>

                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box style={{
                                    padding: '24px 48px',
                                    borderBottom: '1px solid rgba(33, 36, 38, 0.10)',
                                    background: '#FFF',
                                    display: 'flex',
                                    gap: '16px',
                                    width: '100%'

                                }} >
                                    {/* @ts-ignore */}
                                    <Avatar src={receiverProfile} alt={'userName'} styles={{ img: classes.avatarImg, root: classes.avatarImg }} />
                                    <Box display={'flex'} gridGap={'6px'} flexDirection={'column'}>
                                        <Typography className={classes.ActiveUserName}>
                                            {/* @ts-ignore */}
                                            {receiverInfo.userName}
                                        </Typography>
                                        <Typography className={classes.activeStatus}>
                                            Typically responds within few hours
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <div className={classes.chatMsgContainer}>
                                <Typography className={classes.messageDate}>
                                </Typography>
                                {Object.keys(allChatBoxMessages).length > 0 &&
                                    Object.keys(allChatBoxMessages).map((dateKey) => {
                                        const filteredItem = allChatBoxMessages[dateKey]
                                        const item = filteredItem.messages;
                                        return (
                                            <React.Fragment>
                                                <Typography className={classes.messageDate}>
                                                    {filteredItem.date}
                                                </Typography>
                                                {
                                                    item.map((chat, i) => (
                                                        chat.from === 'receiver'
                                                            ? <div className={`${classes.messageContent} receiver`} key={i}>
                                                                <div className={`${classes.messageStyle} ${classes.receiver}`}>
                                                                    {chat.message}
                                                                </div>
                                                                <div className={classes.timeStyle}>
                                                                    {chat.time}
                                                                </div>
                                                            </div> :
                                                            <div className={`${classes.messageContent} sender`} key={i}>
                                                                <div className={`${classes.messageStyle} ${classes.sender}`}>
                                                                    {chat.message}
                                                                </div>
                                                                <div className={classes.timeStyle} style={{ textAlign: 'end' }}>
                                                                    {chat.time}
                                                                </div>
                                                            </div>
                                                    ))
                                                }
                                            </React.Fragment>
                                        )
                                    })}
                            </div>
                            <Box className={classes.chatFooter}>
                                <TextField
                                    placeholder="Write a message..."
                                    type="text"
                                    name="message"
                                    data-test-id={'writeMessageInput'}
                                    value={sendMessageData}
                                    onChange={(event) => handleSendMessage(event.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            sendChatMessageRequest();
                                        }
                                    }}
                                    fullWidth
                                    variant="standard"
                                    InputProps={{
                                        endAdornment: (
                                            <img style={{ cursor: 'pointer' }} data-test-id={'sumbitIconBtn'} onClick={() => sendChatMessageRequest()} src={arrowRightCircle} alt='submit' />
                                        ),
                                        classes: {
                                            input: classes.input,
                                            root: classes.inputRoot
                                        },
                                        disableUnderline: true
                                    }}
                                />
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};

const stylesAddReview = makeStyles((theme) => ({
    paperMainWrappercum: {
        position: 'absolute' as const,
        width: '451px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.18)',
        border: '1px solid #C3CAD9',
        borderRadius: '32px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'initial' as const,
    },
    modalEditContentcum: {
        // padding: '32px 32px 40px 32px',
        maxWidth: '100%',
        // maxHeight: '100%',
        height: '80vh',
        overflow: 'auto',
    },
    textAndCloseStylecum: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px'
    },
    closeButtoncum: {
        cursor: 'pointer',
    },
    distanceBtwncum: {
        marginTop: '20px'
    },
    ActiveUserName: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: '250px'

    },
    activeStatus: {
        color: 'rgba(33, 36, 38, 0.75)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
    },
    chatFooter: {
        display: 'inline-flex',
        height: '120px',
        padding: '24px 48px 56px 48px',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '491px',
        borderTop: '1px solid rgba(33, 36, 38, 0.10)',
        background: '#FFF'
    },
    chatMsgContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '4px',
        margin: '0',
        flex: '1 0 0',
        overflowY: 'auto',
        padding: '0 20px'
    },
    messageStyle: {
        display: 'inline-flex',
        flexDirection: 'column' as const,
        gap: '8px',
        padding: '12px 16px',
        border: '0.5px solid rgba(33, 36, 38, 0.10)',
        background: '#FFF',
        wordWrap: 'break-word' as const,
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
    },
    sender: {
        borderRadius: '16px 0px 16px 16px',
    },
    receiver: {
        borderRadius: '0px 16px 16px 16px',
    },
    timeStyle: {
        color: 'rgba(33, 36, 38, 0.65)',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%',
    },
    messageContent: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '8px',
        maxWidth: '90%',
        '&.sender': {
            alignSelf: 'end',
        },
        '&.receiver': {
            alignSelf: 'start'
        }
    },
    messageDate: {
        color: 'rgba(33, 36, 38, 0.75)',
        fontSize: '14px',
        lineHeight: 'normal',
        margin: '0 auto 16px'
    },
    inputRoot: {
        alignItems: 'flex-start'
    },
    input: {
        padding: 0
    },
    container: {
        margin: '49px auto 120px',
        maxWidth: '90%',
        width: '100%'
    },
    subContainer: {
        margin: '69px auto 140px',
        maxWidth: '90%',
        width: '100%'
    },
    avatarRoot: {
        width: '54px',
        height: '54px',
    },
    avatarImg: {
        objectFit: 'cover',
    },

}));

export default ChatBoxPopup;

