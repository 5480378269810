import React from "react";
// Customizable Area Start
import {
  Button,
  Box,
  Divider,
  Typography,
  Grid,
  Avatar, Tooltip,
  Chip, Tabs, Tab
} from "@material-ui/core";
import {
  Star, Language, Facebook, Instagram
} from '@material-ui/icons';
import { Rating, Pagination } from "@material-ui/lab"
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import TripPriceFilter from "../../../components/src/TripPriceFilter.web";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import TripDateFilter from "../../../components/src/TripDateFilter.web";

import ServiceRatingFilter from "../../../components/src/ServiceRatingFilter";
import CustomCheckboxFilter, { OptionCheckboxFilter } from "../../../components/src/CustomCheckboxFilter";
import CustomRadioFilter, { OptionRadioFilter } from "../../../components/src/CustomRadioFilter";
import CountryFilter from "../../../components/src/CountryFilter";
import CityFilter from "../../../components/src/CityFilter";
import LazyImage from "../../../components/src/LazyImage";
import { capitalizeWords, formatMonthDateYear } from "../../../components/src/utils";
import { primaryColor } from "../../../components/src/theme";
import { noTripMatched } from "../../catalogue/src/assets";
import FilterSelect from "../../../components/src/FilterSelect";
import { reviewCircle } from "../../ordermanagement/src/assets";
import AddReviewWeb from "../../../components/src/AddReview.web";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import ChatBoxPopup from "../../../components/src/ChatBoxPopup.web";
import Loader from "../../../components/src/Loader.web";


const tripTypeFilterOptionsSP: OptionCheckboxFilter[] = [
  {
    label: "Easy",
    noOfTrips: 300,
    value: "Easy",
    selected: false,
  },
  {
    label: "Medium",
    noOfTrips: 255,
    value: "Medium",
    selected: false,
  },
  {
    label: "Hard",
    noOfTrips: 255,
    value: "Hard",
    selected: false,
  },
]
const radioFilterOptionsSP: OptionRadioFilter[] = [
  {
    label: "Yes",
    noOfTrips: 300,
    value: "true",
  },
  {
    label: "No",
    noOfTrips: 255,
    value: "false",
  },
  {
    label: "All",
    noOfTrips: 45,
    value: "all",
  },

]

const sortByOderFilterSP = [
  { value: 'highToLow', label: 'Rating (high to low)' },
  { value: 'lowToHigh', label: 'Rating (low to high)' },
  { value: 'ZtoA', label: 'Price (high to low)' },
  { value: 'AtoZ', label: 'Price (low to high)' },]

const TripCardSP = (classes: any, list: Record<string, any>[], title: string, navigation: any) => {

  const tripList = [...list];

  return <Grid container spacing={5}>

    {
      tripList.map((trip, index) => {
        const { title, provider_type,
          price, discounted_price, currency_code, city, country, primary_image_url,
          average_rating, account: { data: { attributes: { agency_logo: agent_img, first_name, last_name } } }
        } = trip.attributes

        return (
          <Grid item md={4} key={trip.id}>
            <div data-test-id="checkid" key={trip.id} className={classes.innerTripSlideBlockAS}>
              <div data-test-id="navigateToTripDetails1" onClick={() => navigation.navigate('TripDetails', { id: trip.id })} className={classes.imageVignetteAS}>
                <LazyImage src={primary_image_url} alt="Image" className={`${classes.imageAS} ${classes.fixedImgAS} `} />
                <Box className={classes.agencyDetailsAS}>
                  <Avatar alt="AgencyLogo" src={agent_img} className={classes.badgeAS} />
                  <Typography className={classes.font14AS} style={{
                    color: '#FFF',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '150%',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%'
                  }} component={'pre'}>
                    <Tooltip title={`${first_name + ' ' + last_name} (${capitalizeWords(provider_type)})`} arrow>
                      <Button classes={{ label: classes.serviceNameAS }} style={{ padding: 0 }}>
                        {first_name + ' ' + last_name}
                      </Button>
                    </Tooltip> ({capitalizeWords(provider_type)})
                  </Typography>
                  <Chip
                    label={
                      <>
                        {Number.isInteger(average_rating) ? average_rating : average_rating?.toFixed(1)}
                        <Rating value={average_rating * 0.2} precision={0.1}
                          readOnly size="medium" max={1}
                          style={{ color: "#FE7F2D", margin: 'auto 0' }}
                          classes={{
                            icon: classes.ratingIconAS
                          }}
                        />
                      </>
                    }
                    classes={{ label: classes.chipLabelAS }}
                    className={classes.chipAS}
                  />
                </Box>
              </div>
              <Typography data-test-id="navigateid2" onClick={() => navigation.navigate('TripDetails', { id: trip.id })} className={classes.tripNameAS}>
                {title}
              </Typography>
              <Typography className={classes.tripLocationAS}>
                {country}, {city}
              </Typography>
              <Typography className={classes.priceAS}>
                {discounted_price
                  ? <>{discounted_price} {currency_code} <s className={classes.strikeThroughAS}>{price} {currency_code}</s></>
                  : <>{price} {currency_code}</>
                }
              </Typography>
            </div>
          </Grid>
        )
      })}
  </Grid >
}
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class ServiceProviderDetails extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      countryList,
      cityList,
      currencyList,
      tripType,
      travelerType,
      vibeList,
      periodList,
      tripList,
      loader,
      pagination,
      guidedTour,
      femalesOnly,
      kidsFriendly,
      currency,
      priceFrom,
      priceTo,
      serviceRating,
      groupPreferenceFilterOptions,
      tripDesignerFilterOptions,
      country,
      city, tab,
      providerType,
      serviceProviderDetails,
      reviews,
      isSuccessResponse,
      responseStatusModal,
      responseMsg,
      chatBoxModal,
      chatBoxAllMessages,
      sendMessageData,
      receiverInfo,
      countByRatingOption
    } = this.state;

    const tripsBlock = () => {
      return (
        <Box>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '32px 0', width: '100%' }}>
            <Typography style={{ color: primaryColor, textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => this.handleResetFilters()} data-test-id="resetFilter"
            >Reset Filters</Typography>
            <div style={{ marginLeft: 'auto' }}>
              <FilterSelect
                data-test-id="sortFilterSelect"
                advancedSearch={true}
                field="Sort by"
                options={sortByOderFilterSP}
                filterName="sortBy"
                handleFilterChange={(filterName, value) => this.handleSorting(filterName, value, 'serviceProvider')}
              />
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <Box className={classes.filtersContainerAS}>
                <Typography style={{ padding: '0 16px', fontSize: '20px', fontWeight: 500 }} variant="h4">Filters</Typography>
                <Divider />
                <TripPriceFilter
                  data-test-id="priceFilter"
                  currencyList={currencyList}
                  priceFrom={priceFrom}
                  priceTo={priceTo}
                  currency={currency}
                  onChange={(name, value) => this.handleSingleFilterValueSelect(name, value, "serviceProvider")}
                  />
                <Divider />
                <TripDateFilter
                  data-test-id="dateFilter"
                  onChange={(name, formattedDate) => this.handleSingleFilterValueSelect(name, formattedDate, "serviceProvider")}
                />
                <Divider />
                <div style={{ padding: '0px 16px' }}>
                  <ServiceRatingFilter
                    data-test-id="serviceRatingFilter"
                    serviceRatingChange={(value) => this.handleServiceRatingChange(value, "serviceProvider")}
                    selectedValue={serviceRating} 
                    countByRatingOption={countByRatingOption}
                    />
                </div>
                <Divider />
                <CustomCheckboxFilter
                  data-test-id="whatAreFilter"
                  title="What are you up to?"
                  options={tripType}
                  stateName="tripType"
                  handleCheckboxChange={(option) => this.handleCheckboxSelect(option, "tripType", "serviceProvider")}
                  />
                <Divider />
                <CountryFilter
                  data-test-id="countryFilter"
                  country={country}
                  countries={countryList}
                  handleCountryChange={(name, value) => this.handleSingleFilterValueSelect(name, value, "serviceProvider")}
                  getCityListRequest={this.getCityListApiASRequest}
                />
                <Divider />
                <CityFilter
                 data-test-id="cityFilter"
                  city={city}
                  cities={cityList}
                  handleCityChange={(name, value) => this.handleSingleFilterValueSelect(name, value, "serviceProvider")}
                    />
                <Divider />
                <CustomCheckboxFilter
                data-test-id="customFilter"
                  title="Traveler Type"
                  options={travelerType}
                  stateName="travelerType"
                  handleCheckboxChange={(option) => this.handleCheckboxSelect(option, "travelerType", "serviceProvider")}
                />
                <Divider />
                <CustomCheckboxFilter
                  data-test-id="groupPreferenceFilter"
                  title="Group Preference"
                  options={groupPreferenceFilterOptions}
                  stateName="groupPreferenceFilterOptions"
                  handleCheckboxChange={(option) => this.handleCheckboxSelect(option, "groupPreferenceFilterOptions", "serviceProvider")}
                />
                <Divider />
                <CustomCheckboxFilter
                  data-test-id="tripDesignerFilter"
                  title="Trip Designer"
                  options={tripDesignerFilterOptions}
                  stateName="tripDesignerFilterOptions"
                  handleCheckboxChange={(option) => this.handleCheckboxSelect(option, "tripDesignerFilterOptions", "serviceProvider")}
                />
                <Divider />
                <CustomRadioFilter
                data-test-id="guidedTourFilter"
                  selectedValue={guidedTour}
                  title="Guided Tour"
                  options={radioFilterOptionsSP}
                  handleRadioChange={(name, value) => this.handleSingleFilterValueSelect(name, value, "serviceProvider")}
                  stateName="guidedTour"
                />
                <Divider />
                <CustomRadioFilter
                 data-test-id="kidsFriendlyFilter"
                  selectedValue={kidsFriendly}
                  title="Kids Friendly"
                  options={radioFilterOptionsSP}
                  handleRadioChange={(name, value) => this.handleSingleFilterValueSelect(name, value, "serviceProvider")}
                  stateName="kidsFriendly"
                />
                <Divider />
                <CustomRadioFilter
                data-test-id="feamalesOnlyFilter"
                  selectedValue={femalesOnly}
                  title="Females Only"
                  options={radioFilterOptionsSP}
                  handleRadioChange={(name, value) => this.handleSingleFilterValueSelect(name, value, "serviceProvider")}
                  stateName="femalesOnly"
                />
                <Divider />
                <CustomCheckboxFilter
                data-test-id="vibesFilter"
                  title="Vibes"
                  stateName="vibeList"
                  options={vibeList}
                  handleCheckboxChange={(option) => this.handleCheckboxSelect(option, "vibeList", "serviceProvider")}
                />
                <Divider />
                <CustomCheckboxFilter
                  data-test-id="timeFilter"
                  title="Time"
                  options={periodList}
                  stateName="periodList"
                  handleCheckboxChange={(option) => this.handleCheckboxSelect(option, "periodList", "serviceProvider")}
                />

              </Box>
            </Grid>
            <Grid item md={9}>
              {!loader && tripList.length === 0 && <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} gridRowGap={'42px'}
                  flexDirection={'column'} marginBottom={'168px'} marginTop={'160px'}>
                  <img src={noTripMatched} alt='No Bookings' />
                  <Typography className={classes.noBookingTextAS}>
                    {"No trips are matching your search criteria"}
                  </Typography>
                </Box>
              </Grid>} {/* Show image only if tripList is empty */}
              {!loader && tripList.length > 0 && TripCardSP(classes, tripList, 'Culture', this.props.navigation)} {/* Show tripList only if it's not empty and loader is hidden */}

            </Grid>
            <Grid container spacing={5}>
              <Grid item md={3}>
                {""}
              </Grid>
              <Grid container item md={9} justifyContent="center" alignItems="center">
                {pagination.total_pages > 1 &&
                  <Box position={"relative"}>
                    <Box marginY={5}>
                      <Pagination
                        count={pagination.total_pages}
                        page={Number(pagination.current_page)}
                        onChange={this.handlePagination}
                        // defaultPage={1}
                        classes={{
                          root: classes.paginationRootAS,
                          ul: classes.paginationItemAS
                        }}
                      />
                    </Box>
                  </Box>
                }
              </Grid>
            </Grid>
          </Grid>

        </Box>
      )
    }

    const headingImgBlock = (classes: any, icon: string, title: string, style: {} = {}) => {
      return <Box className={classes.flexStyle12} style={style}>
        <img src={icon} alt={title} style={{ height: '24px', width: '24px' }} />
        <Typography className={classes.blockTitle}>{title}</Typography>
      </Box>
    }

    const reviewBtn = ({handleOpenReviewModal}: any) => {
      return <Button 
      onClick={() => handleOpenReviewModal()}
      data-test-id="addReviewBtn"
      style={{
        border: `1px solid ${primaryColor}`,
        color: primaryColor,
        textTransform: 'initial',
        padding: '15px 48px',
        fontSize: '16px',
        fontFamily: 'Inter',
        fontWeight: 500,
        textAlign: 'center',
        lineHeight: '150%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '51px',
        whiteSpace: 'nowrap'
      }}>
        {'Write a Review'}
      </Button>
    }

    const reviewsList = (classes: any, reviews: Record<string, any>[]) => {
      return <>
        {reviews.length
          ? reviews.map((value: Record<string, any>, index: number, array: Record<string, any>[]) => {
            const { rating:avg_rating, commenter, reviewer, title:cmnt_title, comment:desc, created_at } = value?.attributes;
            const reviewerName = commenter?.name || reviewer?.name;
            return <React.Fragment key={index}>
              <Grid container >
                <Grid item md={3}>
                  <Rating
                    name={`rating`}
                    value={avg_rating}
                    precision={0.1}
                    readOnly
                    size="small"
                    style={{
                      color: "#FE7F2D",
                      marginBottom: '20px'
                    }}
                  />
                  <Typography className={classes.fontWeight600} gutterBottom>
                    {reviewerName}
                  </Typography>
                </Grid>
                <Grid item md={9} >
                  <Typography className={`${classes.blockTitle1} ${classes.marginBottom16}`}>
                    {cmnt_title}
                  </Typography>
                  <Typography className={`${classes.marginBottom16} ${classes.textOverflow}`}
                    style={{ color: 'rgba(33, 36, 38, 0.80)' }}>
                    {desc}
                  </Typography>
                </Grid>
              </Grid>
              <Typography style={{
                color: 'rgba(33, 36, 38, 0.80)',
                textAlign: 'end'
              }}>{formatMonthDateYear(created_at)}</Typography>
              {(index !== (array.length - 1)) && <Divider style={{ margin: '16px 0 50px' }} />}
            </React.Fragment>
          })
          : <Typography className={`${classes.fontWeight600}`} style={{
            textAlign: 'center',
          }}>No reviews yet</Typography>}
      </>
    }

    const reviewBlock = (classes: any, reviews: Record<string, any>[]) => {
      return <Box data-testid='reviews'>
        <Box style={{ marginBottom: '48px' }}>
          {headingImgBlock(classes, reviewCircle, 'Reviews')}
        </Box>
        {reviewsList(classes, reviews)}
      </Box>
    }

    const TripsandReviewTabs = () => {
      return <Box style={{ margin: '0 auto', width: '100%' }}>
        <Box className={classes.flexBtnwn}>
          <Tabs
            id="tabs"
            data-test-id="tabsBtn"
            value={tab}
            TabIndicatorProps={{
              style: { backgroundColor: '#FE7F2D', }
            }}
            classes={{ flexContainer: classes.flexContainer, }}
            onChange={(event: any, value: string) =>
              this.handleTabChange(value)
            }
          >
            <Tab label="Trips" value={'0'} classes={{
              selected: classes.selectedTab,
              wrapper: classes.tabItem,
              textColorInherit: classes.textColor
            }} />
            <Tab label="Reviews" value={'1'} classes={{
              selected: classes.selectedTab,
              wrapper: classes.tabItem,
              textColorInherit: classes.textColor
            }} />
          </Tabs>
          {reviewBtn({ handleOpenReviewModal: this.handleOpenReviewModal })}
        </Box>
        <div hidden={tab === '1'}>
          {tripsBlock()}
        </div>
        <div hidden={tab === '0'}>
          {reviewBlock(classes, reviews)}
        </div>
      </Box>
    }

    return (
      <ThemeProvider theme={theme}>
        <Loader loading={loader} />
        <AddReviewWeb
          reviewModal={this.state.openReviewModal}
          addReviewRequest={this.addReviewRequest}
          closeAddReviewModal={this.handleCloseReviewModal}
        />
        <ResponseStatusModal
          isSuccess={isSuccessResponse}
          message={responseMsg}
          open={responseStatusModal}
          onClose={this.closeResponseModal}
        />
        <ChatBoxPopup
          chatBoxModal={chatBoxModal}
          allChatBoxMessages={chatBoxAllMessages}
          sendMessageData={sendMessageData}
          receiverInfo={receiverInfo}
          receiverProfile={serviceProviderDetails.profile_photo}
          handleSendMessage={this.handleSendMessage}
          closeChatBoxModal={this.closeChatBoxModal}
          sendChatMessageRequest={this.sendChatMessageRequest}
        />
        <Box style={{ margin: '32px 0', padding: '0 72px 0 61px' }} maxWidth="lg">
          <Breadcrumbs list={[
            {
              title: 'Home',
              link: '/LandingPage'
            },
            {
              title: `Travel ${providerType} listing`,
              link: providerType === 'agency' ? '/TravelAgencyList' : '/TravelBuddyList'
            },
            {
              title: 'Service Provider'
            }
          ]} />
          <Box className={classes.centerStyle}>
            <div className={classes.columnCenterStyle}>
              <Avatar src={serviceProviderDetails.profile_photo} alt="Image" className={`${classes.image} ${classes.profileImg} ${classes.spaceBetween}`} />
              <Typography className={`${classes.sliderText} ${classes.spaceBetween}`}>
                {serviceProviderDetails.display_name}
              </Typography>
              <Button
                data-test-id="openChatBtn"
                onClick={() => this.openChatBoxModal()}
                className={`${classes.chatBtn} ${classes.spaceBetween}`}>Chat with us</Button>
              <Box className={`${classes.ratingBlock} ${classes.flexStyle8} `}>
                <Star style={{ color: primaryColor }} /> <span style={{ fontWeight: 600 }}>{serviceProviderDetails.average_rating}</span> Rating
                <Divider orientation="vertical" style={{ height: '24px', margin: '0 4px' }} />
                connect with us on 
                <span className={classes.iconsDiv}>
                  <Language
                  data-test-id="webSiteBtn"
                  style={{cursor: 'pointer'}} onClick={() => this.handleSocialMediaClick(serviceProviderDetails.website_link)}/>
                  <Facebook 
                  data-test-id="facebookBtn"
                  style={{cursor: 'pointer'}} onClick={() => this.handleSocialMediaClick(serviceProviderDetails.facebook_link)}/>
                  <Instagram
                  data-test-id="instagramBtn"
                  style={{cursor: 'pointer'}} onClick={() => this.handleSocialMediaClick(serviceProviderDetails.instagram_link)} />
                </span>
              </Box>
            </div>
          </Box>
          <Divider style={{ margin: '48px 0' }} />
          <div>
            {TripsandReviewTabs()}
          </div>

          {/* Customizable End Start */}
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const advancedSearchStyles = (theme: Theme) => ({
  container: {
    margin: '49px auto 120px',
    maxWidth: '90%',
    width: '100%'
  },
  inputRootAS: { paddingLeft: '32px' },
  searchBarAS: {
    color: 'rgba(33, 36, 38, 0.50)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  blockTitle: {
    fontSize: "20px",
    fontWeight: 600,
    letterSpacing: "0.2px",
    opacity: 0.9
  },
  searchIconAS: {
    right: '12px',
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute' as 'absolute',
    // pointerEvents: 'none' as 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FE7F2D',
    textTransform: 'none' as 'none',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    borderRadius: '25px',
    width: '113px',
    maxHeight: '40px',
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#ffffff',
    }
  },
  filtersContainerAS: {
    width: "100%",
    maxWidth: "300px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: '20px 0',
    borderRadius: "8px"
  },
  flexContainer: {
    gap: '80px',
    '& .MuiTab-root': {
      padding: '8px',
      minWidth: 'unset',
      minHeight: 'unset',
    }
  },
  selectedTab: {
    '& span': {
      color: primaryColor,
      fontWeight: 700,
    }
  },
  iconsDiv: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
  },
  tabItem: {
    color: '#000',
    textAlign: 'center' as 'center',
    fontFamily: 'Inter',
    fontSize: '23px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    textTransform: 'none' as 'none',
    letterSpacing: 0
  },
  textColor: {
    opacity: 1
  },
  blockTitle1: {
    fontSize: "20px",
    fontWeight: 600,
  },
  fontWeight600: {
    fontWeight: 600,
  },
  marginBottom16: {
    marginBottom: '16px'
  },
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical' as 'vertical',
  },
  sliderText: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap' as const,
    cursor: 'pointer',
    '&.travelAgency': {
      textAlign: 'center'
    }
  },
  spaceBetween: {
    marginBottom: '32px'
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '16px',
    // objectFit: 'fill' as 'fill',
  },
  profileImg: {
    width: '160px !important',
    height: '160px !important',
    cursor: 'pointer',
    borderRadius: '50%',
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  chatBtn: {
    backgroundColor: primaryColor,
    color: '#ffffff',
    textTransform: 'initial' as const,
    borderRadius: '49px',
    padding: '10px 50px',
    fontSize: '16px',
    fontFamily: "Inter",
    fontWeight: 400,
    width: '214px',
    height: '48px',
    lineHeight: '150%',
    whiteSpace: 'nowrap' as const,
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#ffffff',
    }
  },
  ratingBlock: {
    color: "rgba(33, 36, 38, 0.80)",
    fontWeight: 500,
  },
  flexBtnwn: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  centerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  columnCenterStyle: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center'
  },
  flexStyle8: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
  },
  flexStyle12: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: '16px'
  },
  imageAS: {
    width: '100%',
    height: 'auto',
    borderRadius: '16px',
  },
  innerTripSlideBlockAS: {
    maxHeight: '226px',
    maxWidth: '300px',
    position: 'relative' as 'relative',
    [theme.breakpoints.up(768)]: {
      maxHeight: '213px',
    },
    [theme.breakpoints.up(1024)]: {
      maxHeight: '300px',
    },
    [theme.breakpoints.up(1440)]: {
      maxHeight: '378px',
    },
  },
  imageVignetteAS: {
    position: 'relative' as 'relative',
    cursor: 'pointer',
    '&:before': {
      content: "''",
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      top: 'auto',
      background: 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))',
      pointerEvents: 'none',
      zIndex: 1,
      height: '40%',
      borderRadius: '0 0 16px 16px',
    }
  },
  fixedImgAS: {
    width: '300px !important',
    height: '226px !important'
  },
  agencyDetailsAS: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    gap: '8px',
    position: 'absolute' as const,
    bottom: 0,
    height: '49px',
    width: '100%',
    zIndex: 1
  },
  badgeAS: {
    '&.MuiAvatar-root': { width: '24px', height: '24px', }
  },
  font14AS: {
    fontSize: '14px',
  },
  serviceNameAS: {
    fontFamily: 'Inter',
    color: '#FFF',
    fontStyle: 'normal',
    lineHeight: '150%',
    fontWeight: 500,
    display: 'block',
    whiteSpace: 'nowrap' as const,
    textOverflow: 'ellipsis',
    maxWidth: '106.5px',
    overflow: 'hidden',
    textTransform: 'none' as const,
    fontSize: '14px',
    justifyContent: 'flex-start'
  },
  chipAS: {
    background: 'white',
    maxWidth: '58px',
    maxHeight: '27px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '17px',
    color: '#F5950B'
  },
  chipLabelAS: {
    color: '#F5950B',
    textAlign: 'right' as const,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.301px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 16px'
  },
  ratingIconAS: {
    '& svg': {
      height: '16px',
      width: '16px',
    }
  },
  tripNameAS: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: 0,
    marginTop: '16px',
    whiteSpace: 'nowrap' as const,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  tripLocationAS: {
    color: 'rgba(33, 36, 38, 0.85)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: 0,
    marginTop: '8px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical' as const,
    WebkitLineClamp: 1,
    overflow: 'hidden'
  },
  priceAS: {
    display: 'flex',
    gap: '12px',
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    alignItems: 'center',
    letterSpacing: 0,
    marginTop: '16px',
  },
  strikeThroughAS: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    textDecorationLine: 'line-through',
  },
  paginationRootAS: {
    '& .MuiPaginationItem-root': {
      color: 'rgba(33, 36, 38, 0.50)',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      display: 'flex',
      width: '60.206px',
      height: '32px',
      justifyContent: 'center',
      alignItems: 'center',
      '&.Mui-selected': {
        color: '#FE7F2D',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    '& li:first-child': {
      borderRadius: '8px',
      border: '1px solid rgba(33, 36, 38, 0.20)',
      padding: '4px 4.9px'
    },
    margin: 'auto',
    '& li:last-child': {
      borderRadius: '8px',
      border: '1px solid rgba(33, 36, 38, 0.20)',
      padding: '4px 4.9px'
    },

  },
  paginationItemAS: {
    "& .Mui-selected, & .Mui-selected:hover": {
      backgroundColor: "transparent", // Change this to the desired background color
      color: primaryColor, // Change this to the desired text color
    }
  },
  noBookingTextAS: {
    color: 'rgba(33, 36, 38, 0.90)',
    textAlign: 'center' as const,
    fontFamily: 'Inter',
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '120%',
  },
})

const ServiceProviderDetailsWeb = withStyles(advancedSearchStyles)(ServiceProviderDetails)
export { ServiceProviderDetailsWeb }

// Customizable Area End
