import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, userIcon } from "./assets";
import React from "react";
import { FormikErrors, FormikTouched } from "formik";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { OptionListItem } from "../../email-account-registration/src/Interface.web";
import { convertDateFormat, formatDate, handleScrollToTop } from "../../../components/src/utils";
import toast from 'react-hot-toast';
import parsePhoneNumber from 'libphonenumber-js'
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  firstName: any;
  lastName: any;
  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: Record<string, any>;
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  isEditProfile: boolean;
  countryOption: { label: string, value: string }[];
  isEmailVerified: boolean;
  setErrorFun: any;
  loading: boolean;
  authToken: string;
  userDetailsLeft: Record<string, any>;
  userDetailsRight: Record<string, any>;
  modifiedValue: Record<string, any>;
  profile_image: string;
  countriesData: OptionListItem[];
  countdown: number;
  isOpen: boolean;
  otp: string;
  otpErrorMessage: string | null;
  otpHeaderTitle: string | null;
  isProfileDeleted: boolean;
  openStatusModal: boolean;
  successIcon: boolean;
  modalMsg: string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  labelFirstName: string;
  lastName: string;
  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  userAttr: any;
  sendVerifyEmailApiCallId: any;
  getUserProfileApiCall: any;
  getCountryListApiCallId: any;
  updateUserProfileCallId: any;
  timer: any;
  emailToken: string = '';
  otpVerifyApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: {},
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      isEditProfile: false,
      countryOption: [],
      isEmailVerified: true,
      setErrorFun: null,
      authToken: '',
      loading: false,
      userDetailsLeft: {
        'First Name': '',
        'Email Address': '',
        'Birthdate': '',
        'Nationality': ''
      },
      userDetailsRight: {
        'Last Name': '',
        'Mobile Number': '',
        'Gender': '',
        'Country of Residence': 'a'
      },
      modifiedValue: {},
      countriesData: [],
      profile_image: userIcon,
      countdown: 60,
      otp: "",
      otpErrorMessage: null,
      otpHeaderTitle: null,
      isOpen: false,
      isProfileDeleted: false,
      openStatusModal: false,
      successIcon: false,
      modalMsg: ''
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    const CountryCodeMessage = getName(MessageEnum.CountryCodeMessage)
    const RestAPIResponceMessage = getName(MessageEnum.RestAPIResponceMessage)
    const SessionResponseMessage = getName(MessageEnum.SessionResponseMessage)

    if (message.id === CountryCodeMessage) {
      this.handleCountryCodeRes(message);
    } else if (message.id === RestAPIResponceMessage) {
      this.handleApiResponse(message);
    } else if (message.id === SessionResponseMessage) {
      this.handleSessionResponse(message);
    }
    // Customizable Area End
  }

  validateMobileAndThenUpdateUserProfile() {
    let countryCode: any = this.state.currentCountryCode;
    let mobileNo: any = this.state.phoneNumber;

    let error: any = "";

    error = this.validateCountryCodeAndPhoneNumber(countryCode, mobileNo);

    if (error) {
      this.showAlert(configJSON.errorTitle, error);

      return;
    }

    if (this.userAttr) {
      const countryCodeOld = this.userAttr.country_code;
      const mobileNoOld = this.userAttr.phone_number;

      if (
        Number.parseInt(countryCode) === Number.parseInt(countryCodeOld) ||
        countryCode === configJSON.hintCountryCode
      ) {
        countryCode = null;
      }

      if (
        Number.parseInt(this.state.phoneNumber) === Number.parseInt(mobileNoOld)
      ) {
        mobileNo = null;
      }
    }

    if (mobileNo && countryCode) {
      this.validateMobileOnServer(
        this.state.currentCountryCode,
        this.state.phoneNumber
      );
    } else {
      this.validateAndUpdateProfile();
    }
  }

  validateEmail(email: string) {
    let error = null;

    if (!this.isValidEmail(email)) {
      error = configJSON.errorEmailNotValid;
    }

    return error;
  }

  validateLastName(lastName: String) {
    return !this.isNonNullAndEmpty(lastName)
      ? "Last name " + configJSON.errorBlankField
      : null;
  }

  validateFirstName(firstName: String) {
    return !this.isNonNullAndEmpty(firstName)
      ? "First name " + configJSON.errorBlankField
      : null;
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (
        !this.isNonNullAndEmpty(String(countryCode)) ||
        configJSON.hintCountryCode === countryCode
      ) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (
      this.isNonNullAndEmpty(countryCode) &&
      configJSON.hintCountryCode !== countryCode
    ) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  validateAndUpdateProfile() {
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let countryCode: any = this.state.currentCountryCode;

    let mobileNo = this.state.phoneNumber;
    let email = this.state.email;

    let currentPwd = this.state.currentPasswordText;
    let newPwd = this.state.newPasswordText;
    let reTypePwd = this.state.reTypePasswordText;

    const errorFirstName = this.validateFirstName(firstName);
    const errorLastName = this.validateLastName(lastName);

    const errorMobileNo = this.validateCountryCodeAndPhoneNumber(
      countryCode,
      mobileNo
    );
    const errorEmail = this.validateEmail(email);

    const errorCurrentPwd = this.validateCurrentPwd(currentPwd);
    const errorNewPwd = this.validatePassword(newPwd);
    const errorRetypePwd = this.validateRePassword(reTypePwd);

    let isValidForSignUp: boolean = true;

    if (errorFirstName != null) {
      this.showAlert(configJSON.errorTitle, errorFirstName);
      return false;
    } else if (errorLastName != null) {
      this.showAlert(configJSON.errorTitle, errorLastName);
      return false;
    }

    if (configJSON.ACCOUNT_TYPE_EMAIL === this.registrationAndLoginType) {
      if (errorMobileNo !== null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType
    ) {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);
        return false;
      }
    } else if (
      configJSON.ACCOUNT_TYPE_PHONE === this.registrationAndLoginType
    ) {
      if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    } else {
      if (errorMobileNo != null) {
        this.showAlert(configJSON.errorTitle, errorMobileNo);

        return false;
      } else if (errorEmail != null) {
        this.showAlert(configJSON.errorTitle, errorEmail);

        return false;
      }
    }

    if (
      configJSON.ACCOUNT_TYPE_SOCIAL !== this.registrationAndLoginType &&
      this.state.llDoChangePwdContainerVisible
    ) {
      if (errorCurrentPwd != null) {
        this.showAlert(configJSON.errorTitle, errorCurrentPwd);
        return false;
      } else if (errorNewPwd != null) {
        this.showAlert(configJSON.errorTitle, errorNewPwd);
        return false;
      } else if (errorRetypePwd != null) {
        this.showAlert(configJSON.errorTitle, errorRetypePwd);
        return false;
      } else if (newPwd !== reTypePwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorBothPasswordsNotSame
        );
        return false;
      } else if (currentPwd === newPwd) {
        this.showAlert(
          configJSON.errorTitle,
          configJSON.errorCurrentNewPasswordMatch
        );
        return false;
      }
    }

    //Call update API
    if (this.userAttr) {
      let firstNameOld = this.userAttr.first_name;
      let lastNameOld = this.userAttr.last_name;
      let countryCodeOld = this.userAttr.country_code + "";
      let mobileNoOld = this.userAttr.phone_number + "";
      let emailOld = this.userAttr.email;
      this.registrationAndLoginType = this.userAttr.type;

      if (this.isNonNullAndEmpty(firstName) && firstName === firstNameOld) {
        firstName = null;
      }

      if (this.isNonNullAndEmpty(lastName) && lastName === lastNameOld) {
        lastName = null;
      }

      if (
        this.isNonNullAndEmpty(countryCode) &&
        countryCode === countryCodeOld
      ) {
        countryCode = null;
      }

      if (this.isNonNullAndEmpty(mobileNo) && mobileNo === mobileNoOld) {
        mobileNo = null;
      }

      if (countryCode != null || mobileNo != null) {
        if (countryCode == null) {
          countryCode = countryCodeOld;
        }

        if (mobileNo == null) {
          mobileNo = mobileNoOld;
        }
      }

      if (this.isNonNullAndEmpty(email) && email === emailOld) {
        email = null;
      }
    }

    if (
      this.isNonNullAndEmpty(firstName) ||
      this.isNonNullAndEmpty(lastName) ||
      this.isNonNullAndEmpty(countryCode) ||
      this.isNonNullAndEmpty(mobileNo) ||
      this.isNonNullAndEmpty(email) ||
      (this.isNonNullAndEmpty(currentPwd) && this.isNonNullAndEmpty(newPwd))
    ) {
      const header = {
        "Content-Type": configJSON.contentTypeApiUpdateUser,
        token: this.authToken
      };

      let data: any = {
        first_name: this.state.firstName,
        last_name: this.state.lastName
      };

      if (this.state.edtMobileNoEnabled) {
        if (
          configJSON.hintCountryCode !== countryCode &&
          this.isNonNullAndEmpty(String(countryCode)) &&
          this.isNonNullAndEmpty(String(mobileNo))
        ) {
          data = {
            ...data,
            ...{ new_phone_number: String(countryCode) + String(mobileNo) }
          };
        }
      }

      if (this.isNonNullAndEmpty(email)) {
        data = { ...data, ...{ new_email: email } };
      }

      if (
        this.isNonNullAndEmpty(currentPwd) &&
        this.isNonNullAndEmpty(newPwd)
      ) {
        data = {
          ...data,
          ...{ current_password: currentPwd, new_password: newPwd }
        };
      }

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallMessageUpdateProfileRequestId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiEndPointUpdateUser
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  validateCurrentPwd(currentPwd: any) {
    if (!this.isNonNullAndEmpty(currentPwd)) {
      return configJSON.errorCurrentPasswordNotValid;
    } else {
      return null;
    }
  }

  validatePassword(newPwd: any) {
    if (!this.passwordReg.test(newPwd)) {
      return configJSON.errorNewPasswordNotValid;
    } else {
      return null;
    }
  }

  validateRePassword(reTypePwd: any) {
    if (!this.passwordReg.test(reTypePwd)) {
      return configJSON.errorReTypePasswordNotValid;
    } else {
      return null;
    }
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateMobileOnServer(countryCode: any, mobileNo: any) {
    const header = {
      "Content-Type": configJSON.contenttypeApiValidateMobileNo,
      token: this.authToken
    };

    const data = {
      new_phone_number: countryCode + mobileNo
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiChangePhoneValidation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiValidateMobileNo
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  enableDisableEditPassword(isEditable: boolean) {
    if (configJSON.ACCOUNT_TYPE_SOCIAL === this.registrationAndLoginType) {
      this.setState({
        edtEmailEnabled: false,
        llDoChangePwdContainerVisible: false,
        llChangePwdDummyShowContainerVisible: false
      });
    } else {
      if (isEditable) {
        this.setState({
          llDoChangePwdContainerVisible: true,
          llChangePwdDummyShowContainerVisible: false
        });
      } else {
        this.setState({
          llDoChangePwdContainerVisible: false,
          llChangePwdDummyShowContainerVisible: true,
          currentPasswordText: "",
          newPasswordText: "",
          reTypePasswordText: ""
        });
      }
    }
  }

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  requestSessionData() {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.uniqueSessionRequesterId = msg.messageId;
    this.send(msg);
  }

  getUserProfile() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.authToken
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getCountryList();
    const result = await getStorageData('authToken')
    this.setState({ loading: true, authToken: result }, () => {
      this.getUserProfileData()
    });
  }

  handleOtpChange = (otp: string) => {
    this.setState({ otp: otp });
    if (otp.length === 4) {
      this.setState({ otpErrorMessage: null })
    }
  }

  ResendOtpRequest = () => {
    if (this.state.countdown === 0) {
      this.handleVerifyEmailApi(this.state.email)
    }
  }

  startCountdown = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => ({ countdown: prevState.countdown - 1 }), () => {
        if (this.state.countdown === 0) {
          clearInterval(this.timer as number | undefined);
          if (this.state.otp.length === 0) {
            this.setState({ otpHeaderTitle: "Please enter the OTP you received, if not please resend" });
          }
        }
      })
    }, 1000);
  }

  handleCountryCodeRes = (message: Message) => {
    let selectedCode = message.getData(
      getName(MessageEnum.CountyCodeDataMessage)
    );

    if (selectedCode !== undefined) {
      this.setState({
        currentCountryCode:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  }

  handleApiResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.validationApiCallId:
          this.validationApiRes(responseJson)
          break;
        case this.userProfileGetApiCallId:
          this.handleUserProfileRes(responseJson, errorReponse)
          break;
        case this.apiChangePhoneValidation:
          this.handleChangePhoneRes(responseJson, errorReponse)
          break;
        case this.apiCallMessageUpdateProfileRequestId:
          this.handleUpdateProfileRes(message)
          break;
        case this.getUserProfileApiCall:
          this.handleCustomerUserProfile(responseJson, errorReponse)
          break;
        case this.getCountryListApiCallId:
          this.handleCountryListResponse(responseJson)
          break;
        case this.updateUserProfileCallId:
          this.handleUpdateProfileResponse(responseJson)
          break;
        case this.sendVerifyEmailApiCallId:
          this.handleSendEmailOtpResp(responseJson)
          break;
        case this.otpVerifyApiCallId:
          this.verifyEmailOtp(responseJson)
          break;
        default:
          break;
      }
    }
  }

  handleSendEmailOtpResp(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      if (this.state.otpHeaderTitle) {
        this.setState({
          otpHeaderTitle: "We sent you the OTP to your email address",
          countdown: 60, otpErrorMessage: null, otp: "",
        });
        this.emailToken = responseJson.meta.token
        this.startCountdown();
      } else {
        this.openCloseModal()
        this.emailToken = responseJson.meta.token
        this.setState({ otpHeaderTitle: `Verification code sent to you on ${this.state.email}` })
        this.startCountdown()
      }
    } else if (responseJson && responseJson.errors && responseJson.errors.length) {
      this.state.setErrorFun('email', `* ${responseJson.errors[0].otp}`)
    }
  }

  handleSessionResponse = (message: Message) => {
    let requesterId = message.getData(
      getName(MessageEnum.SessionRequestedBy)
    );

    if (requesterId === this.uniqueSessionRequesterId) {
      const sessionToken = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      this.authToken = sessionToken;

      this.getUserProfile();
    }
  }

  validationApiRes = (responseJson: any) => {
    this.arrayholder = responseJson.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData) {
        if (regexData.password_validation_regexp) {
          this.passwordReg = new RegExp(
            regexData.password_validation_regexp
          );
        }

        if (regexData.email_validation_regexp) {
          this.emailReg = new RegExp(regexData.email_validation_regexp);
        }

        if (regexData.email_validation_regexp) {
          this.setState({
            passwordHelperText: regexData.password_validation_rules
          });
        }
      }
    }
  }

  handleUserProfileRes = (responseJson: any, errorReponse: any) => {
    if (
      responseJson.data &&
      responseJson.data.attributes
    ) {
      //On User Profile Success

      this.userAttr = responseJson.data.attributes;

        let email = this.userAttr.email;
        let firstName = this.userAttr.first_name;
        let lastName = this.userAttr.last_name;
        let currentCountryCode = this.userAttr.country_code;
        let phoneNumber = this.userAttr.phone_number
          ? this.userAttr.phone_number
          : "";

        this.setState({
          email: email,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber
        });

        //@ts-ignore
        this.txtInputFirstNameProps.value = firstName;

        //@ts-ignore
        this.txtInputLastNameProps.value = lastName;

        //@ts-ignore
        this.txtInputEmailProps.value = email;

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = phoneNumber;

        this.registrationAndLoginType = this.userAttr.type;

        if (this.userAttr.country_code) {
          this.setState({ currentCountryCode: currentCountryCode });
        }

        switch (this.registrationAndLoginType) {
          case configJSON.ACCOUNT_TYPE_EMAIL:
            this.setState({
              edtEmailEnabled: false,
              llChangePwdDummyShowContainerVisible: true
            });
            this.txtInputEmailProps.editable = false;
            break;
          case configJSON.ACCOUNT_TYPE_SOCIAL:
            this.setState({
              edtEmailEnabled: false,
              llChangePwdDummyShowContainerVisible: false,
              llDoChangePwdContainerVisible: false
            });
            this.txtInputEmailProps.editable = false;
            break;
          case configJSON.ACCOUNT_TYPE_PHONE:
            this.setState({
              llChangePwdDummyShowContainerVisible: true,
              edtMobileNoEnabled: false,
              countryCodeEnabled: false
            });
            this.txtInputPhoneNumberProps.editable = false;
            break;
          default:
            break;
        }
    } else if (
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      responseJson.errors[0].token
    ) {
      this.showAlert("Session Expired", "Please Log in again.");
    } else {
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleChangePhoneRes = (responseJson: any, errorReponse: any) => {
    if (responseJson != null && responseJson.errors === undefined) {
      //On Change Phone Validation Success
      this.validateAndUpdateProfile();
    } else {
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorReponse);
  }

  handleUpdateProfileRes = (responseJson: any) => {
    if (responseJson != null && responseJson.errors === undefined) {
      //On Change Phone Validation Success

      this.showAlert("Success", "Profile updated successfully.");
      this.enableDisableEditPassword(false);
      this.getUserProfile();
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleCustomerUserProfile = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson.data && responseJson.data.attributes) {
      const {
        first_name,
        last_name,
        email,
        date_of_birth,
        gender,
        nationality,
        country_of_residence,
        profile_image,
        phone_number: {
          country_code,
          number
        }
      } = responseJson.data.attributes
      const phoneNumber = parsePhoneNumber(`+${country_code} ${number}`)

      this.setState({
        loading: false,
        userDetailsLeft: {
          'First Name': first_name,
          'Email Address': email,
          'Birthdate': date_of_birth ? formatDate(new Date(date_of_birth)) : date_of_birth,
          'Nationality': nationality
        },
        userDetailsRight: {
          'Last Name': last_name,
          'Mobile Number': phoneNumber ? phoneNumber.formatInternational() : '',
          'Gender': gender,
          'Country of Residence': country_of_residence
        },
        data: responseJson.data.attributes,
        profile_image: profile_image
      })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  handleCountryListResponse(responseJson: any) {
    const countriesArray = responseJson.country_data.data;
    if (countriesArray) {
      const countryList = countriesArray.map((country: any) => {
        return { label: country.attributes.name, value: country.id }
      })
      const countryCodeList: OptionListItem[] = countriesArray.map(
        (country: any) => {
          const attributes = country.attributes;
          attributes.country_code = '+' + attributes.country_code; // Add '+' sign
          return { ...attributes, id: attributes.alpha_code };
        }
      );
      this.setState({
        countryOption: countryList,
        countriesData: countryCodeList,
      });
    }
  }

  async handleUpdateProfileResponse(responseJson: any) {
    if (responseJson && responseJson.messages && responseJson.messages.length) {
      
      if(this.state.modifiedValue.hasOwnProperty('first_name')){
        const storedData = await getStorageData('userData');
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          parsedData.first_name = this.state.modifiedValue.first_name;
          await setStorageData('userData', JSON.stringify(parsedData));
          document.dispatchEvent(new CustomEvent("userNameUpdate", { detail: this.state.modifiedValue.first_name }));
        }
      }
      this.setState({
        openStatusModal: true,
        successIcon: true,
        modalMsg: responseJson.messages[0].account,
      }, () => {
        this.getUserProfileData();
        this.handleToggleEditBtn()
      })
     
    } else if (responseJson && responseJson.errors && responseJson.errors.length) {
      const { email = null, full_phone_number = null } = responseJson.errors[0].account
      // toast.error(email ? email.join(' | ') : full_phone_number.join(' | '))
      this.setState({
        loading: false,
        openStatusModal: true,
        successIcon: false,
        modalMsg: email ? email.join(' | ') : full_phone_number.join(' | ')
      })
    }
  }

  verifyEmailOtp(responseJson: any) {
    if (responseJson && responseJson.messages && responseJson.messages.length) {
      this.setState({ isEmailVerified: true })
      this.openCloseModal()
    } else if (responseJson && responseJson.errors && responseJson.errors.length) {
      this.setState({ otpErrorMessage: `* ${responseJson.errors[0].otp}` })
    }
  }

  txtInputFirstNameProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNameProps.value = text;
    }

  };

  txtInputLastNameProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNameProps.value = text;
    }
  };

  txtInputPhoneNumberlWebProps = {
    onChangeText: (text: string) => {
      if (this.txtInputPhoneNumberlWebProps.editable) {
        this.setState({ phoneNumber: text })

        //@ts-ignore
        this.txtInputPhoneNumberProps.value = text;
      }
    },
    editable: true
  };

  txtInputPhoneNumberlMobileProps = {
    ...this.txtInputPhoneNumberlWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtInputPhoneNumberProps = this.isPlatformWeb()
    ? this.txtInputPhoneNumberlWebProps
    : this.txtInputPhoneNumberlMobileProps;

  txtInputEmailWebProps = {
    value: "",
    editable: true,
    onChangeText: (text: string) => {
      if (this.txtInputEmailProps.editable) {
        this.setState({ email: text })
        this.txtInputEmailProps.value = text
      }
    }
  }

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    keyboardType: "email-address",
  }

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  btnEnableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(true)
  }

  txtInputCurrentPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ currentPasswordText: text })
      this.txtInputCurrentPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.txtInputCurrentPasswordProps.secureTextEntry });
      this.txtInputCurrentPasswordProps.secureTextEntry = !this.txtInputCurrentPasswordProps.secureTextEntry
      this.imgPasswordShowhideProps.source = this.txtInputCurrentPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputNewPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ newPasswordText: text })
      this.txtInputNewPasswordProps.value = text
    },
    value: "",
    secureTextEntry: true
  }

  btnNewPasswordShowHideButtonProps = {
    onPress: () => {
      this.setState({
        enableNewPasswordField: !this.txtInputNewPasswordProps.secureTextEntry
      });
      this.txtInputNewPasswordProps.secureTextEntry = !this.txtInputNewPasswordProps.secureTextEntry
      this.imgNewPasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  imgNewPasswordShowhideProps = {
    source: imgPasswordVisible
  }

  txtInputReTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePasswordText: text })
      this.txtInputReTypePasswordProps.value = text
    },
    secureTextEntry: true,
    value: ""
  }

  imgReTypePasswordShowhideProps = {
    source: imgPasswordVisible
  }

  btnReTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.txtInputReTypePasswordProps.secureTextEntry
      });
      this.txtInputReTypePasswordProps.secureTextEntry = !this.txtInputReTypePasswordProps.secureTextEntry
      this.imgReTypePasswordShowhideProps.source = this.txtInputNewPasswordProps.secureTextEntry ? imgPasswordVisible : imgPasswordInVisible
    }
  }

  btnDisableEditPasswordProps = {
    onPress: () => this.enableDisableEditPassword(false)
  }

  handleToggleEditBtn = () => {
    this.setState({
      isEditProfile: !this.state.isEditProfile
    })
    handleScrollToTop()
  }

  handleImageUpload = (e: any, setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>,setFieldError: (field: string, message: string | undefined) => void) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      if (fileType === "image/webp") {
        setFieldError('profile_image', "File does not support.")
          return;
      }
      setFieldValue('profile_image', selectedFile);
      this.setState({ isProfileDeleted : false });
    }
  }

  deleteProfile = (setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>) => {
    this.setState({ isProfileDeleted: true })
    setFieldValue('profile_image', null);
  }

  handleChangeEmail = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>,
    touched: FormikTouched<any>
  ) => {
    setFieldValue('email', e.target.value)
    touched.email = true;
    const { target: { value = '' } = {} } = e
    this.setState({ email: value })
    if (value === this.state.data.email) {
      this.setState({ isEmailVerified: true })
    } else if (this.state.isEmailVerified) {
      this.setState({ isEmailVerified: false })
    }
  }

  handleVerfiyEmail = (email: string, error: any, setFieldError: (field: string, message: string | undefined) => void) => {
    if (email && (!error.email || error.email === "* Please verify email address")) {
      this.handleVerifyEmailApi(email)
    }
    this.setState({ setErrorFun: setFieldError })
  }

  handleVerifyEmailApi = (email: string): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const attrs = {
      email: email
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendVerifyEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendVerifyEmailOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  verifyOtp = (e: any) => {
    e.preventDefault();
    if (this.state.otp.length === 4) {
      const header = {
        "Content-Type": configJSON.validationApiContentType
      };

      const attrs = {
        otp_code: this.state.otp,
        token: this.emailToken
      };

      const data = {
        attributes: attrs
      };

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.otpVerifyApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailVerifyOtpApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.callTypeApiValidateMobileNo
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.setState({ otpErrorMessage: "* Please enter 4 digit OTP" })
    }
  }

  getUserProfileData(): boolean {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "Authorization": `Bearer ${this.state.authToken}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserProfileApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCountryList = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      "Accept-Language": 'en'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUpdateProfile = (values: any, setFieldError: (field: string, message: string | undefined) => void) => {
    if (!this.state.isEmailVerified) {
      setFieldError('email', "* Please verify email address")
      return
    }

    this.setState({ loading: true , modifiedValue: values })
    const header = {
      "Authorization": `Bearer ${this.state.authToken}`
    };

    const formdata = new FormData()
    Object.entries(values).forEach(([key]) => {
      if (key === "selectedCountry" || key === "mobile_number") {
        const fullPhoneNumber = values.selectedCountry + values.mobile_number;
        formdata.append('full_phone_number', fullPhoneNumber);
      } else if (key === "birth_day") {
        const date_of_birth = convertDateFormat(new Date(values.birth_day))
        formdata.append("date_of_birth", date_of_birth);
      } else {
        formdata.append(key, values[key])
      }
    })
    if(this.state.isProfileDeleted) {
      formdata.delete('profile_image');
      formdata.append('profile_image', '');
      formdata.append('delete_profile_image', "true");
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateUserProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  openCloseModal = () => {
    if (this.state.isOpen) {
      clearInterval(this.timer as number | undefined)
      this.setState({ countdown: 60, otpHeaderTitle: null, otp: '' })
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  closeStatusModal = () => {
    this.setState({
      openStatusModal: false
    })
  }

  renderProfileImage = () => {
    return this.state.profile_image ?  this.state.profile_image  : userIcon
  }

  isEditProfileStyle = () => {
    return this.state.isEditProfile ? '48px' : '32px'
  }

  // Customizable Area End

}
